import { NextSeo } from 'next-seo'
import { InferGetStaticPropsType } from 'next'
import { extractImgSrc } from '@plaiceholder/tailwindcss/utils'
import { getPlaiceholder } from 'plaiceholder'
import { gql } from '@apollo/client'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import claireBodyImage from '../public/images/team/claire_cutout_underline.png'
import claireHeadImage from '../public/images/team/claire_head.jpg'
import alasdairBodyImage from '../public/images/team/alasdair_cutout_underline.png'
import alasdairHeadImage from '../public/images/team/alasdair_head.jpg'
import CloseQuo_icon from '../public/images/icons/closeQuo_icon.svg?svgr'
import OpenQuo_icon from '../public/images/icons/openQuo_icon.svg?svgr'
import Cosmo_logo from '../public/images/cosmopolitan_logo.svg?svgr'
import WomenAndHome_logo from '../public/images/woman_&_home_logo.svg?svgr'
import Refinery29_logo from '../public/images/refinery29_logo.svg?svgr'
import Independent_logo from '../public/images/the_independent_logo.svg?svgr'
import Express_logo from '../public/images/daily_express_logo.svg?svgr'
import MoneyBackGuarantee from '../components/ui/money-back-guarantee'
import Carousel from '../components/ui/carousel/carousel'
import heroImageDesktop from '../public/images/hero-desktop-couple-at-home.jpg'
import heroImageMobile from '../public/images/hero-mobile-couple-at-home.jpg'
import CircleBlur from '../public/images/circleBlur.svg?svgr'
import Thumb from '../public/images/thumb.png'
import { ImageProps } from '../components/ui'
import ProductLink from '../components/product/product-link'
import TrustpilotBox, { trustpilotData } from '../components/Trustpilot/trustpilot-box'
import FiveStarsIcon from '../public/images/tp_rating_5-star.svg?svgr'
import { Footer, Header } from '../components'
import { client } from '../services/apollo-client'
import { getBlogsBySlugArr } from '../helpers/blogs'
import TrendingArticlesList from '../components/learn/trending-articles-list'
import OneIcon from '../public/images/oneIcon.svg?svgr'
import TwoIcon from '../public/images/twoIcon.svg?svgr'
import ThreeIcon from '../public/images/threeIcon.svg?svgr'
import FourIcon from '../public/images/fourIcon.svg?svgr'
import { hoverStyles } from './learn/index.page'
import { ProductType, TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables } from '@/gql'
import * as UI from '@/ui'

export type HomePageTestProductProps = {
  highlightProducts: InferGetStaticPropsType<typeof getStaticProps>['highlightProducts']
  trendingArticles: InferGetStaticPropsType<typeof getStaticProps>['trendingArticles']
}

// eslint-disable-next-line react/no-multi-comp
const HomePage = ({ highlightProducts, trendingArticles }: HomePageTestProductProps) => {
  if (highlightProducts.length === 0) throw new Error('No product data')

  return (
    <div className="bg-[#e9f0f8] relative overflow-clip">
      <script
        id="homepageJsonLd"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />

      <NextSeo
        title="Become the expert in you with our at home health tests | Selph"
        description="Learn about your health with our at home blood & stool tests. We have tests to check for IBS, coeliac disease, diabetes, bowel cancer & more, all reviewed by doctors."
      />

      <Header darkBg bgColour="blue" />
      <UI.Page containerStyle="none">
        <div className="relative h-[70vh] min-h-[360px] z-0 ">
          <div className="max-w-7xl mx-auto relative flex flex-col justify-around align-center h-full z-10 px-2 sm:px-4 md:px-6 ">
            <UI.Block gap="xxl">
              <UI.Block>
                <UI.Heading
                  size={{ default: 'medium', sm: 'large', md: 'xl' }}
                  weight="regular"
                  color="white"
                  face="museo"
                  className="text-left"
                >
                  <h1>
                    Founded by doctors.
                    <br /> Trusted by thousands.
                  </h1>
                </UI.Heading>
                <UI.Heading
                  size={{ default: 'xxs', sm: 'small', md: 'medium' }}
                  weight="thin"
                  color="white"
                  className="text-left"
                >
                  <h2>Blood and stool tests to help you see your health differently.</h2>
                </UI.Heading>
              </UI.Block>
              <UI.Link type="button" to="tests" size="xl" containerClassName="text-left" color={'pink'}>
                Get a health test <ArrowRightIcon width="20" className="ml-2" />
              </UI.Link>
            </UI.Block>
          </div>

          <picture>
            <source media="(max-width: 768px)" src="true" srcSet={heroImageMobile.src} />
            <UI.Image
              src={heroImageDesktop}
              alt="Couple at home using Selph tests"
              placeholder="blur"
              fill
              priority
              className="object-cover object-top md:object-center"
            />
          </picture>

          <div className="absolute bg-selphBlue-500 inset-0 opacity-50" />
        </div>

        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[90%] md:translate-y-[75%] lg:translate-y-[81%]">
          <CircleBlur className="relative w-[400px] h-[400px] md:w-[800px] md:h-[800px] bottom-0" />

          <div className="absolute h-56 w-56 sm:w-64 sm:h-64 md:h-96 md:w-96 lg:h-[430px] lg:w-[430px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <UI.Image src={Thumb} fill sizes="(max-width: 768px) 224px, (max-width: 1280px) 256px, 430px" alt="" />
          </div>
        </div>

        <UI.Block className="relative mb-32">
          <UI.Block className="py-5 md:py-10 bg-selphGrey-50 w-full border-b-4 border-b-hotPink-500 px-2 sm:px-4 md:px-6 mx-auto">
            <div className="flex max-w-5xl items-center mx-auto">
              <div className="hidden md:flex flex-col gap-y-5">
                <UI.Image
                  src={claireBodyImage}
                  alt="Dr Claire Merrifield, our medical director"
                  placeholder="blur"
                  width={200}
                  className="drop-shadow-lg"
                />
                <UI.Block gap="xs">
                  <UI.Paragraph>Dr Claire Merrifield MRCGP PhD</UI.Paragraph>
                  <UI.Paragraph>our Medical Director</UI.Paragraph>
                </UI.Block>
              </div>
              <UI.Block
                gap="large"
                display="flexCol"
                className="px-2 sm:px-4 md:px-6 mx-auto w-full max-w-6xl py-5 md:py-10"
              >
                <div className="relative">
                  <OpenQuo_icon width={40} className="absolute -top-4 -left-2 opacity-50" />
                  <div className="font-normal lg:text-lg space-y-3">
                    <UI.Paragraph size="xxl" color="hotPink">
                      Preventative, proactive healthcare
                    </UI.Paragraph>
                    <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                      I left the NHS to set up a company that&apos;s about{' '}
                      <span className="text-hotPink-500">preventative, proactive healthcare.</span>
                    </UI.Paragraph>
                    <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                      We need to stop waiting for illness to just happen to us.{' '}
                      <span className="text-hotPink-500">
                        Your health is the most precious gift you&apos;ll ever have.
                      </span>{' '}
                      Selph can help you take care of it.
                    </UI.Paragraph>
                  </div>
                  <CloseQuo_icon width={40} className="absolute right-1 -bottom opacity-50" />
                </div>
                <div className="flex justify-center shrink-0 items-center md:hidden">
                  <UI.Image
                    src={claireHeadImage}
                    width={100}
                    height={100}
                    alt="Dr Claire Merrifield MRCGP PhD"
                    className="rounded-full "
                  />

                  <div className="ml-2">
                    <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>
                      Dr Claire Merrifield MRCGP PhD
                    </UI.Paragraph>
                    <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>our Medical Director</UI.Paragraph>
                  </div>
                </div>
              </UI.Block>
            </div>
            <div className="flex max-w-5xl px-2 sm:px-4 md:px-6 mx-auto items-center ">
              <UI.Block
                gap="large"
                display="flexCol"
                className="px-2 sm:px-4 md:px-6 mx-auto w-full max-w-6xl py-5 md:py-10"
              >
                <div className="relative">
                  <OpenQuo_icon width={40} className="absolute -top-4 -left-2 opacity-50" />
                  <div className="font-normal lg:text-lg space-y-3">
                    <UI.Paragraph size="xxl" color="hotPink">
                      Let&apos;s stop being &quot;normal&quot;
                    </UI.Paragraph>
                    <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                      Traditional healthcare wants us to be &quot;normal&quot;. But normal people get heart disease,
                      cancer and dementia.
                    </UI.Paragraph>
                    <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                      So let&apos;s forget about normal. Selph can not only show you what{' '}
                      <span className="text-hotPink-500">optimal health</span> looks like,{' '}
                      <span className="text-hotPink-500">we can help you get there</span>.
                    </UI.Paragraph>
                  </div>
                  <CloseQuo_icon width={40} className="absolute right-1 -bottom opacity-50" />
                </div>
                <div className="flex justify-center shrink-0 items-center md:hidden">
                  <UI.Image
                    src={alasdairHeadImage}
                    width={100}
                    height={100}
                    alt="Dr Claire Merrifield MRCGP PhD"
                    className="rounded-full "
                  />

                  <div className="ml-2">
                    <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>Dr Alasdair Scott FRCS PhD</UI.Paragraph>
                    <UI.Paragraph size={{ default: 'small', sm: 'medium' }}>our Science Director</UI.Paragraph>
                  </div>
                </div>
              </UI.Block>
              <div className="hidden md:flex flex-col gap-y-5">
                <UI.Image
                  src={alasdairBodyImage}
                  alt="Dr Alasdair Scott, our Science Director"
                  placeholder="blur"
                  width={200}
                  className="drop-shadow-lg"
                />
                <UI.Block gap="xs">
                  <UI.Paragraph>Dr Alasdair Scott FRCS PhD</UI.Paragraph>
                  <UI.Paragraph>our Science Director</UI.Paragraph>
                </UI.Block>
              </div>
            </div>
            <UI.Block
              gap="small"
              className="rounded-3xl border-2 border-selphBlue-400 flex-1 py-6 px-2 sm:px-4 md:px-6 max-w-3xl mx-auto"
            >
              <UI.Heading
                size={{ default: 'xxs', sm: 'xs', md: 'small' }}
                color="midBlue"
                face="museo"
                weight="regular"
                className="text-center"
              >
                <h2>Trust Selph for:</h2>
              </UI.Heading>
              <UI.List listStyle="none" gap="large" margin="none" className="sm:text-lg md:text-xl text-selphBlue-600">
                <UI.ListItem icon iconSize="large" iconPosition="center" itemClassName="leading-tight">
                  <span className="text-hotPink-500">Blood & stool tests</span> for gut health, metabolic health and
                  more.
                </UI.ListItem>
                <UI.ListItem icon iconSize="large" iconPosition="center" itemClassName="leading-tight">
                  <span className="text-hotPink-500">Rapid results</span> - you won&apos;t believe healthcare can be
                  this quick!
                </UI.ListItem>
                <UI.ListItem icon iconSize="large" iconPosition="center" itemClassName="leading-tight">
                  <span className="text-hotPink-500"> Easy-to-understand doctors&apos; reports</span> with tips on
                  optimising your health.
                </UI.ListItem>
                <UI.ListItem icon iconSize="large" iconPosition="center" itemClassName="leading-tight">
                  <span className="text-hotPink-500">Second-to-none customer service</span> to support your health
                  journey.
                </UI.ListItem>
                <UI.ListItem icon iconSize="large" iconPosition="center" itemClassName="leading-tight">
                  Our{' '}
                  <MoneyBackGuarantee
                    className="text-hotPink-500"
                    panelClassName="w-[90vw] md:w-xl left-1/2 -translate-x-1/2 "
                  >
                    Money Back Guarantee
                  </MoneyBackGuarantee>{' '}
                  to give you complete confidence.
                </UI.ListItem>
              </UI.List>
            </UI.Block>
          </UI.Block>

          <div className="md:py-5 max-w-7xl px-2 sm:px-4 md:px-6 mx-auto">
            <UI.Block gap="small">
              <UI.Heading
                size={{ default: 'small', md: 'medium' }}
                color="midBlue"
                weight="regular"
                className="text-center"
              >
                <h2>How Selph health tests have helped our customers</h2>
              </UI.Heading>
              <div className="text-center h-7">
                <TrustpilotBox boxStyle="micro" />
              </div>
            </UI.Block>
            <div className="flex flex-wrap gap-x-5 gap-y-10 justify-between w-full items-stretch">
              {reviews.map((review, i) => (
                <ReviewCard key={i} name={review.name} to={review.to} review={review.review} />
              ))}
            </div>
          </div>

          <div className="py-5 md:py-10 bg-selphGrey-50 w-full border-b-4 border-b-hotPink-500">
            <UI.Block gap="large" className="px-2 sm:px-4 md:px-6 mx-auto w-full max-w-7xl ">
              <UI.Heading
                size={{ default: 'small', md: 'medium' }}
                color="midBlue"
                weight="regular"
                className="text-center"
              >
                <h2>How does Selph work?</h2>
              </UI.Heading>

              <div className="flex flex-wrap max-sm:flex-col justify-between gap-y-10 gap-x-5">
                <UI.Block gap="xs" className="max-w-[280px] mx-auto">
                  <div className="flex items-center gap-x-1 justify-center">
                    <OneIcon className="h-10" />
                    <UI.Heading size="xs">
                      <h3>Choose your test</h3>
                    </UI.Heading>
                  </div>
                  <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="text-center">
                    We have over 20 tests to choose from and each comes with a doctor&apos;s report.
                  </UI.Paragraph>
                </UI.Block>
                <UI.Block gap="xs" className="max-w-[280px] mx-auto">
                  <div className="flex items-center gap-x-1 justify-center">
                    <TwoIcon className="h-10" />
                    <UI.Heading size="xs">
                      <h3>Take your sample</h3>
                    </UI.Heading>
                  </div>
                  <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="text-center">
                    We&apos;ll send you a quick and easy kit you can use at home.
                  </UI.Paragraph>
                </UI.Block>
                <UI.Block gap="xs" className="max-w-[280px] mx-auto">
                  <div className="flex items-center gap-x-1 justify-center">
                    <ThreeIcon className="h-10" />
                    <UI.Heading size="xs">
                      <h3>Post it back (free)</h3>
                    </UI.Heading>
                  </div>
                  <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="text-center">
                    Post your sample back in the free-post envelope
                  </UI.Paragraph>
                </UI.Block>
                <UI.Block gap="xs" className="max-w-[280px] mx-auto">
                  <div className="flex items-center gap-x-1 justify-center">
                    <FourIcon className="h-10" />
                    <UI.Heading size="xs">
                      <h3>Get expert advice</h3>
                    </UI.Heading>
                  </div>
                  <UI.Paragraph size={{ default: 'medium', lg: 'large' }} className="text-center">
                    Our doctors write you a report explaining all your results and any actions you should take.
                  </UI.Paragraph>
                </UI.Block>
              </div>
              <div className="text-center">
                <UI.Paragraph className="inline" size={{ default: 'medium', sm: 'large', md: 'xxl' }}>
                  ...and relax with our{' '}
                </UI.Paragraph>
                <MoneyBackGuarantee
                  className="sm:text-lg md:text-3xl text-hotPink-500"
                  panelClassName="w-[90vw] md:w-xl left-1/2 -translate-x-1/2"
                >
                  Money Back Guarantee
                </MoneyBackGuarantee>
              </div>
            </UI.Block>
          </div>

          <UI.Block gap="large" className="max-w-7xl px-2 sm:px-4 md:px-6 mx-auto md:py-5">
            <UI.Heading
              size={{ default: 'small', md: 'medium' }}
              color="midBlue"
              weight="regular"
              className="text-center"
            >
              <h2>Popular health tests</h2>
            </UI.Heading>

            <Carousel
              slides={highlightProducts.map((product, i) => {
                if (!product) return
                return (
                  <ProductCard
                    key={i}
                    name={product.name}
                    image={product.featuredImage}
                    imageProps={product.featuredImageProps}
                    slug={product.slug}
                  />
                )
              })}
              id="popularProductsCarousel"
            />
            <UI.Link type="button" to="tests" containerClassName="text-center" color={'pink'}>
              View all our tests <ArrowRightIcon width="20" className="ml-2" />
            </UI.Link>
          </UI.Block>
          <div className="md:py-5 bg-selphGrey-50 w-full">
            <UI.Block gap="large" className="py-10 px-2 sm:px-4 md:px-6 mx-auto w-full max-w-7xl">
              <UI.Heading
                size={{ default: 'small', md: 'medium' }}
                color="midBlue"
                weight="regular"
                className="text-center"
              >
                <h2>Featured in</h2>
              </UI.Heading>
              <div className="flex flex-wrap gap-x-10 gap-y-10 items-center justify-center">
                {features.map((feature) => (
                  <UI.Link type="text" to={feature.link} key={feature.name}>
                    {feature.logo}
                  </UI.Link>
                ))}
              </div>
            </UI.Block>
          </div>

          <UI.Block gap="large" className="max-w-7xl px-2 sm:px-4 md:px-6 mx-auto text-center md:py-5">
            <UI.Heading size={{ default: 'small', md: 'medium' }} color="midBlue" weight="regular">
              <h2>Trending articles</h2>
            </UI.Heading>
            <TrendingArticlesList articles={trendingArticles} />

            <UI.Link type="button" color="pink" to="learn" size="xl" containerClassName="text-center">
              Visit the Learn Hub <ArrowRightIcon className="h-5 w-10" />
            </UI.Link>
          </UI.Block>
          <div className="px-2 sm:px-4 md:px-6 py-5 md:py-10">
            <UI.Block
              gap="small"
              className="rounded-3xl bg-selphGrey-50 flex-1 py-6 px-2 sm:px-4 md:px-6 shadow-[4px_4px_0px_0_#e22e89] max-w-5xl mx-auto text-center"
            >
              <UI.Heading
                size={{ default: 'xs', sm: 'small', md: 'medium' }}
                className="text-center"
                color="hotPink"
                face="museo"
                weight="regular"
              >
                <h2>Our promise to you</h2>
              </UI.Heading>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                Health is your priority and <span className="text-hotPink-500">you&apos;re our priority</span>. Nothing
                means more to us than you.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                So, simply put, <span className="text-hotPink-500">we promise to look after you.</span>
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                If you need help - we&apos;re here.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                If you&apos;ve got a question, we&apos;ll respond quickly and kindly.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }}>
                If something goes wrong with your test, we&apos;ll put it right. Nothing is too much trouble.
              </UI.Paragraph>
              <UI.Paragraph size={{ default: 'medium', sm: 'large', md: 'xl' }} className="inline">
                We&apos;re very confident in our service and want you to be confident too. That&apos;s why all our tests
                are protected by our{' '}
              </UI.Paragraph>{' '}
              <MoneyBackGuarantee
                className="sm:text-lg md:text-xl text-hotPink-500"
                panelClassName="w-[90vw] md:w-xl left-1/2 -translate-x-1/2"
              >
                Money Back Guarantee.
              </MoneyBackGuarantee>
            </UI.Block>
          </div>
        </UI.Block>
      </UI.Page>
      <Footer />
    </div>
  )
}

export const getStaticProps = async () => {
  const slugs = [
    'q-fit-bowel-cancer-test',
    'prostate-specific-antigen-psa-blood-test',
    'general-health-blood-test',
    'complete-gut-health-test-symptom-screen',
  ]

  const highlightProducts = await Promise.all(slugs.map((slug) => getProductBySlug(slug)))

  const trendingArticles = await getBlogsBySlugArr([
    '7-evidenced-based-actions-you-can-take-to-prevent-bowel-cancer-or-pick-it-up-earlier',
    'when-should-you-start-screening-for-bowel-cancer',
    'what-is-hba1c-and-whats-the-hba1c-normal-range',
  ])

  return {
    props: {
      highlightProducts,
      trendingArticles,
    },
  }
}

export default HomePage

const features = [
  {
    name: 'Cosmopolitan Magazine',
    logo: <Cosmo_logo width={128} />,
    link: 'https://www.cosmopolitan.com/uk/body/diet-nutrition/a46866572/leaky-gut-syndrome/',
  },
  {
    name: 'Woman & Home',
    logo: <WomenAndHome_logo width={128} />,
    link: 'https://www.womanandhome.com/health-wellbeing/are-greens-powders-a-waste-of-money/',
  },
  {
    name: 'Refinery29',
    logo: <Refinery29_logo width={128} />,
    link: 'https://www.refinery29.com/en-au/2024/06/11643406/coeliac-disease-gluten-free-food-lifestyle-impact',
  },
  {
    name: 'Daily Express',
    logo: <Express_logo width={128} />,
    link: 'https://www.express.co.uk/life-style/health/1886545/bowel-cancer-screening-age',
  },
  {
    name: 'The Independent',
    logo: <Independent_logo width={128} />,
    link: 'https://www.independent.co.uk/life-style/women-mental-health-uk-europe-sadness-b2542377.html',
  },
]

const reviews = [
  {
    name: 'Joanna',
    to: 'https://uk.trustpilot.com/reviews/66642eafe296f323117f689e',
    review: (
      <UI.Paragraph color="midBlue" size="large">
        This is the company for our family.{' '}
        <span className="text-hotPink-500">It is rare to encounter such a personal touch as a customer</span> in any
        sphere of business or public service nowadays. I am so impressed and grateful for Selph.
      </UI.Paragraph>
    ),
  },
  {
    name: 'Tony',
    to: 'https://uk.trustpilot.com/reviews/6618e607fb54990df59b86d2',
    review: (
      <UI.Paragraph color="midBlue" size="large">
        I ordered a PSA kit which was easy to use and by following all instructions i had my results along with peace of
        mind within 48 hours.{' '}
        <span className="text-hotPink-500">
          With such a short time to wait for results it really does reduce any anxiety.
        </span>
      </UI.Paragraph>
    ),
  },
  {
    name: 'Anna',
    to: 'https://uk.trustpilot.com/reviews/65ce4a605b2c3332ffdfd6e1',
    review: (
      <UI.Paragraph color="midBlue" size="large">
        <span className="text-hotPink-500">Wonderful company</span>, clear instructions. I sent my sample off Tuesday
        afternoon and had my results Wednesday afternoon. The results were very clear and I was able to{' '}
        <span className="text-hotPink-500">put my mind at rest very quickly.</span>
      </UI.Paragraph>
    ),
  },
]

const jsonLdData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  '@id': 'https://www.selph.co.uk/',
  name: 'Selph',
  description: 'Become an expert in your health',
  url: 'https://www.selph.co.uk/',
  image: '/images/thumb.png',
  logo: '/images/logo.svg',
  sameAs: [
    'https://www.facebook.com/selphhealthuk',
    'https://twitter.com/selphhealthuk',
    'https://www.instagram.com/selphhealthuk',
    'https://www.linkedin.com/company/selphhealth/',
    'https://www.youtube.com/@SelphHealth',
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '124 City Road',
    addressLocality: 'London',
    postalCode: 'EC1V 2NX',
    addressCountry: 'GB',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: trustpilotData.value,
    bestRating: 5,
    ratingCount: trustpilotData.count,
  },
}

async function getProductBySlug(slug: string) {
  const response = await client.query<TestProductSummaryBySlugQuery, TestProductSummaryBySlugQueryVariables>({
    query: testProductSummaryBySlug,
    variables: { slug },
  })

  const product = response.data?.productBySlug?.__typename === 'LabTestProduct' ? response.data.productBySlug : null

  if (!product) return null

  const placeholder = await getPlaiceholder(extractImgSrc(`/images/product/${product.featuredImage.src}`))
  const featuredImageProps = {
    blurImage: placeholder.base64,
    ...(placeholder.img as Omit<ImageProps, 'placeholder'>),
  } as ImageProps

  return {
    ...product,
    featuredImageProps,
  }
}

const testProductSummaryBySlug = gql`
  query testProductSummaryBySlug($slug: ID!) {
    productBySlug(slug: $slug) {
      __typename
      ... on LabTestProduct {
        sku
        name
        slug
        featuredImage {
          src
          name
        }
      }
    }
  }
`

// eslint-disable-next-line react/no-multi-comp
const ProductCard = ({
  image,
  name,
  imageProps,
  slug,
}: {
  image: { name: string; src: string }
  imageProps: ImageProps
  name: string
  slug: string
}) => (
  <ProductLink color="none" slug={slug} type={ProductType.LabTest}>
    <div
      className={`pb-4 w-[270px] overflow-clip shadow-[2px_2px_2px_0_#0D4780] rounded-3xl bg-selphGrey-50 ${hoverStyles}`}
    >
      <UI.Block>
        <div className="relative flex items-center justify-center h-[256px]">
          <UI.Image
            className="object-cover object-center"
            fill
            sizes="270px"
            blurDataURL={imageProps.blurImage}
            placeholder="blur"
            src={`/images/product/${image.src}`}
            alt={image.name}
          />
        </div>

        <div className="flex items-center justify-center h-24 px-4">
          <UI.Paragraph className="text-center" size="xl" color="midBlue">
            {name}
          </UI.Paragraph>
        </div>
      </UI.Block>
    </div>
  </ProductLink>
)

// eslint-disable-next-line react/no-multi-comp
const ReviewCard = ({ name, review, to }: { name: string; review: React.ReactNode; to: string }) => (
  <div className="mt-[50px] mx-auto">
    <div className="relative pb-4 w-[280px] min-[340px]:w-xs rounded-2xlh-[300px]">
      <OpenQuo_icon width={40} className="absolute -top-4 opacity-50" />
      <UI.Block gap="small" className="relative px-2 pt-1 text-center ">
        {review}

        <UI.Paragraph color="darkGrey">{name}</UI.Paragraph>
        <CloseQuo_icon width={40} className="absolute opacity-50 left-full -translate-x-[50px] bottom-4" />
      </UI.Block>
      <UI.Link to={to} color="none">
        <FiveStarsIcon className="w-[92px] md:w-[108px] mx-auto mt-2" />
      </UI.Link>
    </div>
  </div>
)
